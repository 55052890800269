export default {
  colors: {
    brown: '#6e442d',
    brownLight: '#926952',
    brownLigher: '#f5f2f1',
    green: '#27c5b7',
    gray: '#403b39',
    grayLight: '#c7c7c7',
    white: '#fff',
  },
  fontSizes: [
    14, 16, 18, 22, 26, 48, 70, 80, 40,
  ],
  breakpoints: ['1024px'],
  fonts: {
    sans: '"Open Sans", system-ui, sans-serif',
  },

  buttons: {
    outline: {
      color: 'white',
      backgroundColor: 'transparent',
      border: '3px solid white',
      textTransform: 'uppercase',
      borderRadius: 0,
      fontSize: 16,
      padding: 18,
      lineHeight: 1,
      transition: 'background-color 300ms ease-in-out, color 300ms ease-in-out',
      '&:hover': {
        backgroundColor: '#fff',
        color: '#27c5b7',
      }
    }
  }
};
