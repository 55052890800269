import { I18n } from '@lingui/react';
import React from 'react';
import styled from '@emotion/styled';

const Selector = styled.select`
  font-size: ${ ({ theme }) => theme.fontSizes[1] }px;
  color: ${ ({ theme }) => theme.colors.white };
  background-color: transparent;
  border: none;
  -moz-appearance: none; 
  -webkit-appearance: none; 
  appearance: none;
`;

const SelectorWrapper = styled.div`
  font-size: ${ ({ theme }) => theme.fontSizes[0] }px;
  color: ${ ({ theme }) => theme.colors.white };
  &:after {
    content: '▼';
    padding-left: 5px;
  }
`;

const handleLocaleChange = (e) => {
  document.location.href = e.target.value;
};

const LocaleSelector = () => {
  return (
    <I18n>
      { ({ i18n }) => {
        return (
          <SelectorWrapper>
            <Selector onChange={ handleLocaleChange }>
              <option selected={ i18n._language === 'pt' } value={ '/' }>PT</option>
              <option selected={ i18n._language === 'en' } value={ '/en' }>EN</option>
            </Selector>
          </SelectorWrapper>
        );
      } }
    </I18n>
  );
};

export default LocaleSelector;
