import React from 'react';
import { Link, Image } from '@rebass/emotion';
import { ScrollTo } from 'react-scroll-to';
import logoIcon from '../../images/logo.svg';
import { rem } from '../../utils';

const Logo = props => {
  return (
    <ScrollTo>
      { ({ scrollTo }) => (
        <Link href={ '#' } onClick={ e => {
          e.preventDefault();
          scrollTo({ y: 0, smooth: true });
        } } width={rem(140)} { ...props }>
          <Image src={ logoIcon } />
        </Link>
      ) }
    </ScrollTo>
  );
};

export default Logo;
