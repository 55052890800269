const languages = [ 'pt', 'en' ];
const catalogs = {
  en: require('./locales/en/messages.js'),
  pt: require('./locales/pt/messages.js'),
};
const locales = {
  'en': 'en_US',
  'pt': 'pt_BR',
};

const defaultLanguage = 'pt';

const prefix = lang => (lang === defaultLanguage ? '/' : '/' + lang);
const deprefix = pathname => (pathname.startsWith('/en/') ? pathname.substr(4) : pathname);
const langFromPath = pathname => (pathname.startsWith('/pt/') ? 'pt' : 'en');

exports.defaultLanguage = defaultLanguage;
exports.languages = languages;
exports.locales = locales;
exports.catalogs = catalogs;
exports.prefix = prefix;
exports.deprefix = deprefix;
exports.langFromPath = langFromPath;
