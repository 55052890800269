import React, { Component } from 'react';
import { Box, Flex } from '@rebass/emotion';
import { rem } from '../../utils';
import { css } from '@emotion/core';
import Container from '../Grid/Container';
import LocaleSelector from './LocaleSelector';
import Logo from './Logo';
import Menu from './Menu';
import MenuIcon from './MenuIcon';
import homeClip from '../../images/homeClip.svg';
import { backgroundStyle } from './styles';

const containerStyles = (theme, withoutBackground) => css`
  ${ !withoutBackground && backgroundStyle(theme) }
  padding: ${ withoutBackground ? rem(30) : rem(15) } 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  transition: background 300ms ease-in-out, padding 300ms ease-in-out;
`;

const logoStyles = (withoutBackground) => css`
  transition: max-width 300ms ease-in-out;
  max-width: ${ withoutBackground ? rem(100) : rem(70) };
`;

class Header extends Component {
  constructor (props) {
    super(props);

    this._handleMenuClick = this._handleMenuClick.bind(this);
    this._handleMenuClose = this._handleMenuClose.bind(this);
    this._handleScroll = this._handleScroll.bind(this);

    this.el = React.createRef();

    this.state = {
      isMenuOpen: false,
      withoutBackground: true,
    };
  }

  componentDidMount () {
    this._handleScroll();

    window.addEventListener('scroll', this._handleScroll);
  }

  render () {
    return (
      <Box ref={ this.el } width={ 1 } css={ theme => containerStyles(theme, this.state.withoutBackground) }>
        <Container alignItems={ 'center' } css={ css`justify-content: space-between; position: relative;` }>
          <Logo css={ logoStyles(this.state.withoutBackground) } />
          <Menu menu={ this.props.menu } open={ this.state.isMenuOpen } handleMenuClose={ this._handleMenuClose } />
          <Flex alignItems={ 'center' }>
            <LocaleSelector/>
            <MenuIcon open={ this.state.isMenuOpen } onClick={ this._handleMenuClick } ml={ rem(20) } />
          </Flex>
        </Container>
      </Box>
    );
  }

  _handleMenuClick (e) {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ isMenuOpen: !this.state.isMenuOpen });
  }

  _handleMenuClose() {
    this.setState({ isMenuOpen: false });
  }

  _handleScroll (e) {
    if (!this.el.current || document.getElementsByTagName('html')[0].style.position === 'fixed') {
      return null;
    }

    const elHeight = this.el.current.getBoundingClientRect().height;

    this.setState({
      withoutBackground: !(window.scrollY > elHeight),
    });
  }
}

Header.propTypes = {};

export default Header;
