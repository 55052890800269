import { css } from '@emotion/core';
import { Link } from '@rebass/emotion';
import React from 'react';
import PropTypes from 'prop-types';
import { rem } from '../../utils';
import { ScrollTo } from 'react-scroll-to';

const linkActiveStyle = theme => css`
  color: ${ theme.colors.green };
`;

const linkStyle = theme => css`
  text-transform: uppercase;
  color: ${ theme.colors.white };
  font-weight: bold;
  margin-bottom: ${ rem(50) };
  text-align: center;
  
  @media(min-width: 1024px) {
    margin-bottom: 0;
    margin-right: 30px;
    letter-spacing: 1px;
  }
  
  &:hover {
    color: ${ theme.colors.green };
  }
`;

const MenuItem = ({ title, target, url, handleMenuClose }) => {
  const handleClick = (e, scrollTo) => {
    if (!url.startsWith('#')) {
      return null;
    }

    e.preventDefault();

    const elId = url.replace('#', '');
    const el = document.getElementById(elId);

    if (!el) {
      return null;
    }

    const elPosition = el.offsetTop - 70;

    handleMenuClose();
    scrollTo({ y: elPosition, smooth: true });
  };

  return (
    <ScrollTo>
      { ({ scrollTo }) => (
        <Link target={ target }
          href={ url }
          fontSize={ [4, 2] }
          css={ theme => linkStyle(theme) }
          onClick={ e => {
            handleClick(e, scrollTo);
          } }>{ title }</Link>
      ) }
    </ScrollTo>
  );
};

MenuItem.propTypes = {
  target: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
  handleMenuClose: PropTypes.func.isRequired,
};

export default MenuItem;
