import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Text } from '@rebass/emotion';
import { rem } from '../../utils';
import Container from '../Grid/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitterSquare, faFacebookSquare, faLinkedin } from '@fortawesome/free-brands-svg-icons';

const FooterContainer = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${ rem(25) } 0;
  background-color: ${ ({ theme }) => theme.colors.brown };
`;

const SocialIcons = styled.div`
  align-self: center;
`;

const SocialLink = styled.a`
  display: inline-flex;
  margin-right: ${ rem(15) };
  
  svg {
    color: ${ ({ theme }) => theme.colors.white };
    transition: color 300ms ease-in-out;
  }
  
  &:hover {
    svg {
      color: ${ ({ theme }) => theme.colors.green };
    }
  }
`;

const socialIcons = {
  facebook: faFacebookSquare,
  twitter: faTwitterSquare,
  linkedin: faLinkedin,
};

const Footer = ({ address, socialNetwork }) => (
  <FooterContainer>
    <Container width={ 1 } css={ css`justify-content: space-between;` }>
      <SocialIcons>
        { socialNetwork && socialNetwork.items && socialNetwork.items.map(({ title, url }) => (
          <SocialLink key={ title } href={ url } target={ '_blank' }>
            <FontAwesomeIcon size={ 'lg' } icon={ socialIcons[title] } />
          </SocialLink>
        )) }
      </SocialIcons>
      <Text color='white' css={ css`align-self: center;` }>
        { address }
      </Text>
    </Container>
  </FooterContainer>
);

export default Footer;
