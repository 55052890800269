import { css } from '@emotion/core';
import React from 'react';
import { Link, Image } from '@rebass/emotion';
import { rem } from '../../utils';
import iconMenuOpen from '../../images/iconMenuOpen.svg';
import iconMenuClose from '../../images/iconMenuClose.svg';

const MenuIcon = props => {
  const { open, ...otherProps } = props;

  return (
    <Link href={'#'} { ...otherProps } width={ rem(42) } css={ css`
      position: ${ open ? 'absolute' : 'static' };
      right: ${ rem(15) };
      z-index: 1001;
      
      @media(min-width: 1024px) {
        display: none;
      }
    ` }>
      <Image src={ open ? iconMenuClose : iconMenuOpen } />
    </Link>
  );
};

export default MenuIcon;
