import { I18nProvider } from '@lingui/react';
import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { Global, css } from '@emotion/core';
import { ThemeProvider } from 'emotion-theming';
import emotionNormalize from 'emotion-normalize';
import { catalogs } from '../i18n-config';
import Footer from './Footer';
import Header from './Header';
import theme from '../styles/theme';

const Layout = ({ menu, siteData: { acf: siteData }, socialNetwork, children, lang }) => {
  return (
    <I18nProvider language={lang} catalogs={catalogs}>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={siteData.titulo}
        meta={[
          {
            name: 'description',
            content: siteData.descricao,
          },
          {
            property: 'og:title',
            content: siteData.titulo,
          },
          {
            property: 'og:description',
            content: siteData.descricao,
          },
          {
            property: 'og:type',
            content: 'website',
          },
          {
            name: 'twitter:card',
            content: 'summary',
          },
          {
            name: 'twitter:title',
            content: siteData.titulo,
          },
          {
            name: 'twitter:description',
            content: siteData.descricao,
          },
          {
            name: 'keywords',
            content: siteData.keywords,
          },
        ]} />
      <ThemeProvider theme={ theme }>
        <Global styles={ theme => css`
            ${ emotionNormalize }
      
            html, body {
              font-family: ${ theme.fonts.sans };
              color: ${ theme.colors.gray };
            }
            a {
              text-decoration: none;
              color: ${ theme.colors.green };
              transition: color 300ms ease-in-out;
            }
          ` } />
        <Header menu={ menu } />
        { children }
        <Footer address={ siteData && siteData.endereco } socialNetwork={ socialNetwork } />
      </ThemeProvider>
    </I18nProvider>

  );
};

Layout.defaultProps = {
  siteData: {
    acf: {},
  }
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
