import { graphql, StaticQuery } from 'gatsby';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { Flex, Link } from '@rebass/emotion';
import { rem } from '../../utils';
import MenuItem from './MenuItem';
import { backgroundStyle } from './styles';

const containerStyle = (theme, open) => css`
  ${ backgroundStyle(theme) }
  position: fixed;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  max-height: ${ open ? '100vh' : 0 };
  overflow: ${ open ? 'scroll' : 'hidden' };
  transition: max-height 300ms ease-in-out;
  top: 0;
  left: 0;
  z-index: 1000;
  
  @media(min-width: 1024px) {
    position: static;
    width: auto;
    height: auto;
    background: none;
    max-height: none;
    flex: 1;
    justify-content: flex-end;
  }
`;

class Menu extends Component {
  render () {
    return (
      <Flex alignItems={ 'center' }
        flexDirection={ ['column', 'row'] }
        px={ rem(15) }
        css={ theme => containerStyle(theme, this.props.open) }>
        { this.props.menu.items.map(({ title, url, target, wordpress_id }) => (
          <MenuItem key={ wordpress_id } target={ target } url={ url } title={title} handleMenuClose={ this.props.handleMenuClose } />
        )) }
      </Flex>
    );
  }
}

Menu.defaultProps = {
  open: false,
  menu: {
    items: [],
  }
};

Menu.propTypes = {
  items: PropTypes.array,
  open: PropTypes.bool,
  handleMenuClose: PropTypes.func.isRequired,
};

export default Menu;
